import React from 'react';

import './LoadingSpinner.scss';

const LoadingSpinner = (props) => {
	return (
		<div
			className={`loading-spinner-container ${props.asOverlay ? 'overlay' : ''} ${props.asCover ? 'cover' : ''}`}
		>
			<div className="loading-spinner" />
		</div>
	);
};

export default LoadingSpinner;
