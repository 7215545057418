import React from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import SwitchApp from "./core/SwitchApp";
import { AuthContext } from "./shared/context/AuthContext";
import { useAuth } from "./shared/hooks/AuthHook";

const App = () => {
  const { token, userId, username, role, login, logout } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        username: username,
        role: role,
        isAdmin: role === "ADMIN",
        isRoot: role === "ROOT",
        login: login,
        logout: logout,
      }}
    >
      <ToastContainer theme="colored" />
      <SwitchApp />
    </AuthContext.Provider>
  );
};

export default App;
