import React, { Suspense, lazy } from "react";

import { useContext } from "react";
import LoadingSpinner from "../shared/components/UI/LoadingSpinner";
import { AuthContext } from "../shared/context/AuthContext";

const AdminRouter = lazy(() => import("./AdminRouter"));
const StaffRouter = lazy(() => import("./StaffRouter"));
const UnauthenticatedRouter = lazy(() => import("./UnauthenticatedRouter"));

const SwitchApp = () => {
  const authContext = useContext(AuthContext);
  const { token, isAdmin, isRoot } = authContext;
  console.log({ token, isAdmin, isRoot });

  let router;
  if (!token) {
    router = <UnauthenticatedRouter />;
  } else if (isAdmin || isRoot) {
    router = <AdminRouter />;
  } else {
    router = <StaffRouter />;
  }

  return <Suspense fallback={<LoadingSpinner />}>{router}</Suspense>;
};

export default SwitchApp;
